import * as React from 'react';
import { Product } from './types';
import styled, { keyframes } from 'styled-components';
import { formatPrice } from './utils/format';
import { StyledButton } from './Button';
import { useCartContext } from './CartContext';
import { useHistory } from 'react-router';
import { useToken } from './useToken';

export const StyledProductPhoto = styled.figure`
  margin: 0;
  padding: 0;
  background: #627684;
  border-radius: 1rem;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledProductName = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const StyledProductPrice = styled.div`
  font-size: 2rem;
`;

export const StyledProductButton = styled(StyledButton)`
  > :nth-child(3) {
    transform: translateY(100%);
  }

  &[data-quantity='0'] {
    > :first-child {
      transform: none;
    }
    > :nth-child(2) {
      transform: translateY(100%);
    }
  }

  &:not([data-quantity='0']) {
    --color-button: #40b481;

    > :first-child {
      transform: translateY(-100%);
    }
  }

  &[data-quantity='1'] {
    > :nth-child(2) {
      transform: none;
    }
  }

  &:disabled {
    background-color: #e5e5e5;
    color: #b7bac1;
  }

  &:not([data-quantity='0']):not([data-quantity='1']) {
    > :nth-child(3) {
      transform: none;
    }
    > :nth-child(2) {
      transform: translateY(-100%);
    }
  }
`;

const enterProduct = keyframes`
  from {
    transform: translateY(2rem);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
`;

const StyledProductView = styled.div`
  --photo-size: 18rem;
  display: grid;
  width: var(--photo-size);
  margin: 1rem;
  grid-template-rows: var(--photo-size) auto auto 3rem;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 0.5rem;
  grid-template-areas:
    'photo   photo'
    'name    name'
    'details details'
    'price   button';
  animation: ${enterProduct} 0.6s calc(0.1s * var(--i)) var(--ease) both;

  > ${StyledProductPhoto} {
    grid-area: photo;
  }

  > ${StyledProductName} {
    grid-area: name;
  }

  > ${StyledProductButton} {
    grid-area: button;
  }

  > ${StyledProductPrice} {
    grid-area: price;
    align-self: center;
  }
`;

export const ProductPhoto: React.FC<{
  thumbnail: Product['thumbnail'] | null;
}> = ({ thumbnail }) => {
  return (
    <StyledProductPhoto>
      {thumbnail ? (
        <img src={thumbnail.public_url} alt={thumbnail.name} />
      ) : (
        '..'
      )}
    </StyledProductPhoto>
  );
};

export const AddToCartButton: React.FC<{
  product: Product;
  onAddToCart: (product: Product) => void;
}> = ({ product, onAddToCart }) => {
  const [cartState] = useCartContext();
  const isOutOfStock = !product.is_service
    ? product.in_stock <= 0 ||
      product.in_stock <= cartState.items[product.id]?.quantity
    : false;

  return (
    <StyledProductButton
      disabled={isOutOfStock}
      onClick={e => {
        e.stopPropagation();
        onAddToCart(product);
      }}
      data-quantity={cartState.items[product.id]?.quantity || 0}
    >
      <span>Add to Cart</span>
      <span>Added!</span>
      <span>Added 𝘅 {cartState.items[product.id]?.quantity}</span>
    </StyledProductButton>
  );
};

export const ProductView: React.FC<{
  product: Product;
  onAddToCart: (product: Product) => void;
  style?: React.CSSProperties;
}> = ({ product, onAddToCart, style }) => {
  const history = useHistory();
  const token = useToken();

  return (
    <StyledProductView
      style={style}
      onClick={() => {
        history.push(`/${token}/products/${product.id}`);
      }}
    >
      <ProductPhoto thumbnail={product.thumbnail} />
      <StyledProductName>{product.item}</StyledProductName>
      {/* <div>{product.description}</div> */}
      <StyledProductPrice>{formatPrice(product.price)}</StyledProductPrice>
      <AddToCartButton product={product} onAddToCart={onAddToCart} />
    </StyledProductView>
  );
};
