import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useState
} from 'react';
import styled, { keyframes, css } from 'styled-components';

import { ToastProps } from '../Toast.types';
import { Toaster } from '../Toast.context';
import { removeToast } from '../Toast.actions';

function withAlpha(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}

const getTextColorFromMotif = (motif: ToastProps['motif']) => {
  switch (motif) {
    case 'success':
      return '#00660D';
    case 'warning':
      return '#A27500';
    case 'error':
      return '#3F0000';
    case 'info':
      return '#000F38';
  }
};

const getColorFromMotif = (motif: ToastProps['motif']) => {
  switch (motif) {
    case 'success':
      return '#079940';
    case 'warning':
      return '#EEC11A';
    case 'error':
      return '#be1e2d';
    case 'info':
      return '#00426B';
  }
};

const itIsToastTime = keyframes`
  0% {
    opacity: 0;
    transform: translateX(2rem);
  }
  70% {
    opacity: 1;
    transform: translateX(-0.5rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const itIsNoLongerToastTime = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  30% {
    opacity: 1;
    transform: translateX(-0.5rem);
  }
  100% {
    opacity: 0;
    transform: translateX(2rem);
  }
`;

const staleToastCountdown = keyframes`
  from {
    height: 100%;
  }
  to {
    height: 0%;
  }
`;

const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.176) 0px 4px 8px;
  background: white;
  overflow: hidden;
  display: flex;
  border-radius: 6px;
  min-height: 50px;
  min-width: 250px;
  max-width: 400px;
  font-size: 14px;

  &.-entering {
    animation: ${itIsToastTime} 0.2s ease-in both;
  }

  &.-exiting {
    animation: ${itIsNoLongerToastTime} 0.2s ease-in both;
  }

  @media (max-width: 1000px) {
    max-width: 100%;
  }

  background: white;
`;

const IconPanel = styled.div`
  position: relative;
  height: inherit;
  text-align: center;
  color: white;
  width: auto;
  padding: 8px;
  overflow: hidden;

  background: ${(props: ToastProps) => getColorFromMotif(props.motif)};
`;

const StalenessMeter = styled.div`
  opacity: ${(props: Pick<ToastProps, 'lifetime'>) =>
    props.lifetime === 'forever' ? 0 : 1};

  animation: ${(props: Pick<ToastProps, 'lifetime'>) =>
    props.lifetime === 'forever'
      ? 'none'
      : css`
          ${staleToastCountdown} ${props.lifetime}ms linear
        `};

  bottom: 0;
  height: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);

  position: absolute;
`;

const ContentPanel = styled.div`
  padding: 8px 16px 8px 8px;
  flex: 1;

  color: ${(props: ToastProps) => getTextColorFromMotif(props.motif)};
`;

const ClosePanel = styled.div`
  cursor: pointer;
  padding: 8px;
  transition: all 0.3s ease-in-out;

  color: ${(props: ToastProps) => getTextColorFromMotif(props.motif)};

  &:hover {
    color: ${(props: ToastProps) =>
      withAlpha(getTextColorFromMotif(props.motif), 0.6)};
  }
`;

const renderIcon = (motif: ToastProps['motif']) => {
  switch (motif) {
    case 'success':
      return <i className="fas fa-check" />;
    case 'warning': // falls through
    case 'error':
      return <i className="fas fa-exclamation-triangle" />;
    case 'info':
      return <i className="fas fa-info-circle" />;
  }
};

export const Toast: FunctionComponent<ToastProps> = props => {
  const { id, title, content, motif, lifetime } = props;

  const { dispatch } = useContext(Toaster);
  const [stale, setStale] = useState(false);

  useEffect(() => {
    if (lifetime !== 'forever') {
      const timeout = setTimeout(() => setStale(true), lifetime);
      return () => clearTimeout(timeout);
    }
  }, [lifetime, setStale]);

  useEffect(() => {
    if (stale && id) {
      const timeout = setTimeout(() => dispatch(removeToast(id)), 200);
      return () => clearTimeout(timeout);
    }
  }, [stale, dispatch, id]);

  return (
    <Container
      id={`toast-${id}`}
      data-testid={`toast-${id}`}
      className={stale ? '-exiting' : '-entering'}
    >
      <IconPanel motif={motif}>
        <StalenessMeter lifetime={lifetime} />
        {renderIcon(props.motif)}
      </IconPanel>
      <ContentPanel motif={motif}>
        {title && (
          <>
            <strong>{title}</strong>
            <br />
          </>
        )}
        {content}
      </ContentPanel>
      <ClosePanel motif={motif} role="button" onClick={() => setStale(true)}>
        <i className="fas fa-times" />
      </ClosePanel>
    </Container>
  );
};

Toast.defaultProps = {
  motif: 'info',
  lifetime: 5000
};
