const googlePlacesApiUrl = process.env.REACT_APP_GOOGLE_MAPS_API_URL;
const googlePlacesApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export function searchForAddressByComponents(components: {
  address_1: string;
  address_2: string;
  address_zip: string;
  address_city: string;
  address_state: string;
}) {
  const address = Object.keys(components).reduce((sum, key) => {
    const value = components[key as keyof typeof components];
    return `${sum}${encodeURIComponent(value)}`;
  }, '');

  return searchForAddress(address);
}

export async function searchForAddress(address: string) {
  if (address.length < 5) return;

  try {
    const url = `${googlePlacesApiUrl}/geocode/json?key=${googlePlacesApiKey}&address=${address}`;

    console.log('url', url);

    const response = await fetch(url);
    const { results } = await response.json();

    console.log(results);
  } catch (error) {
    console.log('error', error);
    throw error;
  }
}
