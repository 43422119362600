import * as React from 'react';
import { Product } from './types';
import styled from 'styled-components';
import { formatPrice } from './utils/format';
import { useCartContext } from './CartContext';
import { useParams, useHistory } from 'react-router';
import {
  StyledProductName,
  StyledProductPrice,
  ProductPhoto,
  StyledProductPhoto,
  StyledProductButton,
  AddToCartButton
} from './ProductView';
import { useKeyDown } from './useKeyPress';

export const StyledProductDetailView = styled.article`
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;

const StyledProductDescription = styled.p`
  margin: 1rem 0;
  line-height: 1.625;
`;

const StyledProductDetail = styled.div`
  --photo-size: 24rem;
  display: grid;
  grid-template-rows: var(--photo-size) auto auto auto;
  grid-template-columns: auto auto;
  grid-template-areas:
    'photo photo'
    'item item'
    'details details'
    'price button';
  gap: 1rem;
  padding: 2rem;
  width: min-content;
  background: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: var(--radius);

  > ${StyledProductPhoto} {
    width: var(--photo-size);
    grid-area: photo;
  }

  > ${StyledProductName} {
    grid-area: item;
  }

  > ${StyledProductDescription} {
    grid-area: details;
  }

  > ${StyledProductPrice} {
    grid-area: price;
  }

  > ${StyledProductButton} {
    grid-area: button;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    grid-template-rows: 15rem auto auto auto;
    grid-template-columns: 1fr 1fr;
    max-width: 350px;
    min-width: 300px;
    padding: 1rem;

    > ${StyledProductPhoto} {
      width: auto;
      grid-area: photo;
    }
  }
`;

const StyledCloseButton = styled.button`
  appearance: none;
  position: absolute;
  background: transparent;
  border: none;
  top: 0;
  right: 0;
  cursor: pointer;

  &:before {
    font-size: 2rem;
    content: '🅧';
    opacity: 0.3;
  }
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    background: white;
    text-align: center;
    border-radius: 50%;

    &:before {
      display: block;
      font-size: 1.8rem;
      line-height: 1;
      content: '🅧';
      opacity: 0.3;
    }
  }
`;

export const ProductDetailView: React.FC<{
  style?: React.CSSProperties;
  onAddToCart: (product: Product) => void;
}> = ({ onAddToCart }) => {
  const history = useHistory();
  const { id } = useParams();
  const [cartState] = useCartContext();

  const product = cartState.catalog!.products[id];

  const handleClose = React.useCallback(() => {
    history.goBack();
  }, []);

  const handleClickClose = React.useCallback(
    e => {
      if (e.target === e.currentTarget) {
        handleClose();
      }
    },
    [handleClose]
  );

  useKeyDown('Escape', handleClose);

  return (
    <StyledProductDetailView onClick={handleClickClose}>
      <StyledProductDetail>
        <StyledProductName>{product.item}</StyledProductName>
        <ProductPhoto thumbnail={product.thumbnail} />
        <StyledProductDescription>{product.details}</StyledProductDescription>
        <StyledProductPrice>{formatPrice(product.price)}</StyledProductPrice>
        <AddToCartButton product={product} onAddToCart={onAddToCart} />
        <StyledCloseButton onClick={handleClickClose} />
      </StyledProductDetail>
    </StyledProductDetailView>
  );
};
