import React, { useLayoutEffect, useState, useEffect, useMemo } from 'react';
import { useTracking, useTracked } from './useTracker';

export function useFlipping(
  key: string
): {
  ref: React.RefObject<HTMLElement>;
  status: 'idle' | 'flipping';
  styles: React.CSSProperties | undefined;
} {
  const [ref, updateRect] = useTracking(key);
  const rect = useTracked(key);
  const [flipState, setFlipState] = useState<'idle' | 'flipping'>('idle');

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      updateRect();
    });

    resizeObserver.observe(ref.current!);

    return () => {
      resizeObserver.unobserve(ref.current!);
    };
  }, [ref, updateRect]);

  useLayoutEffect(() => {
    // sets [data-flip="flipping"]
    setFlipState('flipping');

    requestAnimationFrame(() => {
      // sets [data-flip="idle"]
      setFlipState('idle');
    });
  }, [rect?.delta]);

  const styles = useMemo(() => {
    return rect?.delta
      ? (({
          // @ts-ignore
          '--dx': rect.delta.x,
          '--dy': rect.delta.y,
          '--dw': rect.delta.width,
          '--dh': rect.delta.height,
        } as any) as React.CSSProperties)
      : undefined;
  }, [rect?.delta]);

  return { ref, status: flipState, styles };
}
