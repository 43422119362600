import { useState } from 'react';

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, (value: T) => void] {
  const [value, setValue] = useState<T>(() => {
    return getLocalStorage<T>(key, initialValue);
  });

  const setStoredValue = (value: T) => {
    setValue(value);
    setLocalStorage(key, value);
  };

  return [value, setStoredValue];
}

export function getLocalStorage<T>(key: string, defaultValue: T): T {
  try {
    const jsonItem = window.localStorage.getItem(key);

    return jsonItem ? JSON.parse(jsonItem) : defaultValue;
  } catch (err) {
    console.log(err);
    return defaultValue;
  }
}

export function setLocalStorage<T>(key: string, value: T): void {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.log(err);
  }
}

export function removeLocalStorage(key: string): void {
  try {
    window.localStorage.removeItem(key);
  } catch (err) {
    console.log(err);
  }
}
