export function loadFattmerchantJs() {
  const id = 'Fattmerchant.js';
  const src = process.env.REACT_APP_FATTMERCHANTJS_URL;
  const isScriptAlreadyLoaded = !!document.getElementById(id);

  return new Promise((resolve, reject) => {
    if (isScriptAlreadyLoaded) {
      resolve();
    } else if (src) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.onload = resolve;
      script.id = id;
      document.head.appendChild(script);
    } else {
      console.error('Fattmerchant.js url is not defined');
      reject();
    }
  });
}
