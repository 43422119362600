import React, { useEffect, useState, useCallback } from 'react';
import {
  useCartContext,
  CartItem,
  getCartTotals,
  CART_ITEM_MIN_QUANTITY
} from './CartContext';
import styled, { keyframes } from 'styled-components';
import { Product } from './types';
import { formatPrice } from './utils/format';
import { StyledButton, StyledButtonLink } from './Button';
import { useRouteMatch } from 'react-router';
import { useFlipping } from './useFlipping';
import { useToken } from './useToken';
import { getDiscount } from './utils';
import { useToaster } from './toast';

const StyledCartInput = styled.input`
  font-size: 1.5rem;
  appearance: none;
  background: rgba(6, 35, 51, 1);
  color: inherit;
  border: none;
  border-radius: 0.1rem;

  &:focus {
    outline: 2px solid #fff;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const StyledCartItemPhoto = styled.figure`
  margin: 0;
  padding: 0;
  background: gray;
  border-radius: var(--radius);
  overflow: hidden;
  border: 1px solid rgba(6, 35, 51, 1);

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const StyledCartQuantity = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  > ${StyledCartInput} {
    text-align: center;
    width: auto;
    border-radius: 0.1rem;

    &:focus {
      outline: 2px solid #fff;
    }
  }

  &:before {
    content: '𝘅';
    margin-right: 1rem;
  }
`;

const StyledCartContent = styled.div`
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledCartPrice = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledCartDeleteButton = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.6;
  }

  &:before {
    font-size: 2rem;
    content: '🅧';
    color: white;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const enterItem = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%)
  }
  to {
    opacity: 1;
    transform: none;
  }
`;

const enterHeader = keyframes`
  from {
    opacity: 0;
    transform: translateY(1.5rem)
  }
  to {
    opacity: 1;
    transform: none;
  }
`;

const StyledCartItem = styled.div`
  display: grid;
  grid-template-columns: 3rem auto 1fr;
  grid-template-rows: 1.5rem 1.5rem;
  grid-template-areas:
    'photo quantity content delete'
    'photo quantity price delete';
  grid-column-gap: 1rem;
  animation: ${enterItem} 0.6s var(--ease);

  margin-bottom: 1rem;

  &[data-quantity='0'] {
    text-decoration: line-through;
    opacity: 0.5;
  }

  > ${StyledCartItemPhoto} {
    grid-area: photo;
  }

  > ${StyledCartQuantity} {
    grid-area: quantity;
  }

  > ${StyledCartContent} {
    grid-area: content;
  }

  > ${StyledCartPrice} {
    grid-area: price;
  }

  > ${StyledCartDeleteButton} {
    grid-area: delete;
  }
`;

const CartItemPhoto: React.FC<{ thumbnail: Product['thumbnail'] }> = ({
  thumbnail
}) => {
  return (
    <StyledCartItemPhoto>
      {thumbnail && <img src={thumbnail.public_url} alt={thumbnail.name} />}
    </StyledCartItemPhoto>
  );
};

const CartQuantity: React.FC<{
  quantity: number;
  max: number;
  disabled: boolean;
  onChange: (quantity: number) => void;
}> = ({ quantity, max, disabled, onChange }) => {
  const [quantityValue, setQuantityValue] = useState(`${quantity}`);

  useEffect(() => {
    setQuantityValue(`${quantity}`);
  }, [quantity]);

  return (
    <StyledCartQuantity>
      <StyledCartInput
        type="number"
        disabled={disabled}
        min={CART_ITEM_MIN_QUANTITY}
        max={max + 1}
        value={quantityValue}
        size={quantityValue.length || 1}
        onClick={e => (e.target as HTMLInputElement).select()}
        onChange={e => {
          setQuantityValue(e.target.value);
          onChange(Number(e.target.value));
        }}
        onBlur={e => {
          /**
           * This might seem redundant but even with the check being done in
           * the cart context the UI input be in a dumb state hence we keep this
           * fixed that issue
           */
          const boundQuantity = Math.max(
            CART_ITEM_MIN_QUANTITY,
            Math.min(+quantityValue, max)
          );

          setQuantityValue(`${boundQuantity}`);
          onChange(boundQuantity);
        }}
      />
    </StyledCartQuantity>
  );
};

export const CartItemView: React.FC<{
  item: CartItem;
  disabled: boolean;
  onDelete: () => void;
  onChangeQuantity: (quantity: number) => void;
  style?: React.CSSProperties;
}> = ({ item, disabled, onDelete, onChangeQuantity, style }) => {
  return (
    <StyledCartItem data-quantity={item.quantity} style={style}>
      <CartItemPhoto thumbnail={item.product.thumbnail} />
      {!item.product.is_discount && (
        <CartQuantity
          quantity={item.quantity}
          max={item.product.is_service ? 999 : +item.product.in_stock}
          disabled={disabled}
          onChange={onChangeQuantity}
        >
          {item.quantity}
        </CartQuantity>
      )}
      {item.product.is_discount ? (
        <>
          <StyledCartContent>
            {item.product.item} ({item.product.code})
          </StyledCartContent>
          <StyledCartPrice>
            {item.product.meta.is_percentage
              ? `${item.product.price}%`
              : formatPrice(item.product.price)}{' '}
            off {item.product.is_taxable && '+ tax'}
          </StyledCartPrice>
        </>
      ) : (
        <>
          <StyledCartContent>{item.product.item}</StyledCartContent>
          <StyledCartPrice>
            {formatPrice(item.product.price)} ea.{' '}
            {item.product.is_taxable && '+ tax'}
          </StyledCartPrice>
        </>
      )}
      {disabled ? null : <StyledCartDeleteButton onClick={onDelete} />}
    </StyledCartItem>
  );
};

const StyledItems = styled.div``;
const StyledDiscounts = styled.div``;

const StyledH2 = styled.h2`
  animation: ${enterHeader} 0.6s var(--ease);
  border-bottom: 1px solid gray;
  margin-bottom: 1rem;
`;

export const StyledTotal = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1rem;

  > span {
    align-self: center;
  }

  > output {
    text-align: right;
    font-size: 1.5rem;
  }

  > ${StyledCartInput} {
    height: 35px;
    font-size: 1rem;
    margin-left: auto;
    min-width: 6rem;
  }
`;

export const StyledCartView = styled.section`
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const StyledCartViewContentWrap = styled.div`
  color: white;
  padding: 2rem;
  border-radius: var(--radius);
  display: grid;
  height: 100%;
  grid-template-columns: 1fr max-content;
  grid-template-rows: auto 1fr auto auto;
  grid-row-gap: 1rem;
  grid-template-areas:
    'header header'
    'items items'
    'discounts discounts'
    '- total'
    'checkout checkout';

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      to bottom left,
      rgba(6, 35, 51, 0.8),
      rgba(6, 35, 51, 1)
    );
    border-radius: inherit;
    transform-origin: top left;
  }

  &:before,
  > * {
    transition: transform 0.6s;
  }

  &[data-cart-visible*='true'] {
    pointer-events: none;
  }

  &[data-flip='flipping'] {
    &:before {
      transition: none;
      transform: translateX(calc(var(--dx) * -1px))
        translateY(calc(var(--dy) * -1px))
        scale(calc(1 / var(--dw)), calc(1 / var(--dh)));
    }

    > * {
      transition: none;
      transform: translateX(calc(var(--dx) * -1px))
        translateY(calc(var(--dy) * -1px));
    }
  }

  > header {
    grid-area: header;
  }

  > ${StyledButtonLink} {
    grid-area: checkout;
  }

  > ${StyledItems} {
    grid-area: items;
  }

  > ${StyledDiscounts} {
    grid-area: discounts;
  }

  > ${StyledTotal} {
    grid-area: total;
  }

  > ${StyledButton} {
    grid-area: checkout;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const StyledCartViewHeader = styled.header`
  display: flex;
  place-items: center;
`;

export const CartView: React.FC = () => {
  const match = useRouteMatch('/:token/checkout');
  const token = useToken();

  const [cartState, cartDispatch] = useCartContext();
  const cartItems = Object.values(cartState.items);
  const cartDiscounts = Object.values(cartState.discounts);
  const [discountCode, setDiscountCode] = useState('');
  const { toaster, toast } = useToaster();

  const {
    subTotal,
    tax,
    total,
    discountBeforeTax,
    discountAfterTax,
    surcharge
  } = getCartTotals(cartState);
  const flipping = useFlipping('cart');
  const disabled = !!cartState.surchargeReviewData;

  const addDiscountCode = useCallback(
    async (code: string) => {
      if (!code) return;

      try {
        const discount = await getDiscount(token, code);
        cartDispatch({
          type: 'product.addToCart',
          product: discount
        });
      } catch (error) {
        toaster(
          toast.error('The specified discount code is expired or invalid')
        );
      }
    },
    [token, cartDispatch]
  );

  return (
    <StyledCartView
      ref={flipping.ref}
      data-flip={flipping.status}
      data-disabled={!!cartState.surchargeReviewData}
      style={flipping.styles}
    >
      <StyledCartViewContentWrap>
        <StyledCartViewHeader>
          <h1>Your Cart</h1>
        </StyledCartViewHeader>
        {cartItems.length > 0 ? (
          <StyledItems>
            {cartItems.map((item, i) => {
              return (
                <CartItemView
                  style={{
                    // @ts-ignore
                    '--i': i
                  }}
                  item={item}
                  key={item.product.id}
                  disabled={disabled}
                  onDelete={() => {
                    cartDispatch({
                      type: 'product.delete',
                      productId: item.product.id
                    });
                  }}
                  onChangeQuantity={quantity => {
                    cartDispatch({
                      type: 'product.quantity',
                      quantity,
                      productId: item.product.id
                    });
                  }}
                />
              );
            })}
          </StyledItems>
        ) : (
          <em>No items yet!</em>
        )}
        {cartDiscounts.length && (
          <StyledDiscounts>
            <StyledH2>Discount{cartDiscounts.length > 1 ? 's' : ''}</StyledH2>
            {cartDiscounts.map((item, i) => {
              return (
                <CartItemView
                  style={{
                    // @ts-ignore
                    '--i': i + cartItems.length
                  }}
                  item={item}
                  key={item.product.id}
                  disabled={disabled}
                  onDelete={() => {
                    cartDispatch({
                      type: 'product.delete',
                      productId: item.product.id
                    });
                  }}
                  onChangeQuantity={quantity => {
                    cartDispatch({
                      type: 'product.quantity',
                      quantity,
                      productId: item.product.id
                    });
                  }}
                />
              );
            })}
          </StyledDiscounts>
        )}
        <StyledTotal>
          {!match &&
            // TODO: determine whether or not we want to support multiple discounts
            !cartDiscounts.length && (
              <>
                <span>Discount Code:</span>
                <StyledCartInput
                  size={discountCode.length || 1}
                  value={discountCode}
                  onChange={e => setDiscountCode(e.target.value)}
                  onBlur={e => {
                    addDiscountCode(e.target.value);
                  }}
                />
              </>
            )}
          <span>Subtotal:</span>
          <output>{formatPrice(subTotal)}</output>
          {discountBeforeTax > 0 && (
            <>
              <span>Before Tax Discount:</span>
              <output>{formatPrice(discountBeforeTax)}</output>
            </>
          )}
          <span>Tax:</span>
          <output>{formatPrice(tax)}</output>
          {discountAfterTax > 0 && (
            <>
              <span>After Tax Discount:</span>
              <output>{formatPrice(discountAfterTax)}</output>
            </>
          )}
          {cartState.store?.is_surcharge_enabled ? (
            <>
              <span>Surcharge:</span>
              <output>{formatPrice(surcharge || 0)}</output>
            </>
          ) : null}
          <span>Total:</span>
          <output>{formatPrice(total)}</output>
        </StyledTotal>

        {!match && total > 0 && (
          <StyledButton onClick={() => cartDispatch({ type: 'checkout' })}>
            Check Out
          </StyledButton>
        )}
      </StyledCartViewContentWrap>
    </StyledCartView>
  );
};
