import React, { Context, createContext, useContext, useReducer } from 'react';
import {
  ToastContextProps,
  ToastContext,
  ToastAction,
  ToastState,
  ToastActionType,
  ToastProps
} from './Toast.types';
import { BreadBag, Toast } from './components';
import { toast } from './Toast.actions';

export function s4(): string {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export function uuid(): string {
  return `${s4()}-${s4()}-${s4()}-${s4()}-${s4()}`;
}

export const Toaster = createContext({} as ToastContext) as Context<
  ToastContext
>;

const initialState: ToastState = { toasts: [] };

type Reducer = (state: ToastState, action: ToastAction) => ToastState;

const storeReducer: Reducer = (state, action) => {
  let newState = state;

  switch (action.type) {
    case ToastActionType.ADD_TOAST:
      const newToast = { ...(action.payload as ToastProps) };
      let content = newToast.content;

      // handle error formats from core api
      if (typeof content === 'object') {
        if ((content as any).message) {
          content = (content as any).message;
        } else {
          content = Object.values(content as any).join('\n');
        }
      }

      newToast.content = content;
      newToast.id = newToast.id || uuid();

      return { ...state, toasts: [...state.toasts, newToast] };
    case ToastActionType.REMOVE_TOAST:
      const id = action.payload as string;
      const toasts = state.toasts.filter(toast => toast.id !== id);
      return { ...state, toasts };
    default:
      break;
  }

  return newState;
};

// i am the provider of the toast
export const ToastProvider = (props: ToastContextProps) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);

  const localContext: ToastContext = {
    state,
    dispatch,
    toast,
    toaster: dispatch
  };

  return (
    <Toaster.Provider value={localContext}>
      <BreadBag>
        {state.toasts.map(props => (
          <Toast key={props.id} {...props} />
        ))}
      </BreadBag>
      {props.children}
    </Toaster.Provider>
  );
};

export function useToaster() {
  return useContext(Toaster);
}
