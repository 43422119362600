import React, { useEffect, useState } from 'react';
import './App.scss';
import { ProductsView, StyledProductsView } from './ProductsView';
import { CheckoutView, StyledCheckoutView } from './CheckoutView';
import { CartView, StyledCartView } from './CartView';
import {
  CartContextProvider,
  useCartContext,
  getCartTotals
} from './CartContext';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  useLocation,
  Switch,
  Route,
  useHistory,
  Redirect
} from 'react-router-dom';
import { Tracker } from './tracker';
import { TrackerContext } from './useTracker';
import {
  ProductDetailView,
  StyledProductDetailView
} from './ProductDetailView';
import { useToken } from './useToken';
import { getStore, getCatalog } from './utils/queryApi';
import { ErrorPage } from './ErrorPage';
import { ToastProvider } from './toast';
import { StyledButton } from './Button';
import { ThemeWrapper } from './ThemeWrapper';
import { Themed } from './types';
import { formatPrice } from './utils/format';

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

// learncssgrid.com
const StyledStore = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 7rem 1fr;
  grid-template-areas:
    'top top'
    'left left'
    'left left';
  height: 100%;
  width: 100%;
  transition: all 0.3s ease-in-out;

  > ${StyledHeader} {
    grid-area: top;
  }

  > ${StyledProductsView} {
    grid-area: left;
  }

  > ${StyledCheckoutView} {
    grid-area: left;
  }

  > ${StyledProductDetailView} {
    grid-area: left;
  }

  > ${StyledCartView} {
    opacity: 0;
    transform: translateX(2rem);
    transition: all 0.3s ease-in-out;
  }

  /** Desktop - Shopping: Cart Enabled */
  &[data-cart-visible*='true'] {
    grid-template-columns: [left] 2fr [right] minmax(600px, 1fr);
    grid-template-areas:
      'top top'
      'left right'
      'left right';

    > ${StyledCartView} {
      grid-area: right;
      transform: translateX(0);
      opacity: 1;
    }
  }

  /** Desktop - Checkout */
  &[data-path*='/checkout'] {
    grid-template-columns: 1fr 2fr;
    grid-template-areas:
      'top top'
      'left right'
      'left right';

    > ${StyledCheckoutView} {
      grid-area: right;
    }

    > ${StyledCartView} {
      grid-area: left;
    }

    /** Always show cart on desktop checkout page */
    &[data-cart-visible*='true'],
    &[data-cart-visible*='false'] {
      > ${StyledCheckoutView} {
        grid-area: right;
      }

      > ${StyledCartView} {
        grid-area: left;
        opacity: 1;
        transform: translateX(0);
      }
    }

    /** Mobile - Checkout */
    @media (max-width: 768px) {
      /** Hide checkout when cart is toggled on */
      &[data-cart-visible*='true'] {
        grid-template-columns: 1fr;
        grid-template-areas:
          'top'
          'left'
          'left';

        > ${StyledCheckoutView} {
          display: none;
        }
      }
      /** Hide cart when cart is toggled off */
      &[data-cart-visible*='false'] {
        grid-template-columns: 1fr;
        grid-template-areas:
          'top'
          'right'
          'right';

        > ${StyledCartView} {
          display: none;
        }
      }
    }
  }

  /** Mobile - Shopping */
  @media (max-width: 768px) {
    grid-template-columns: [left] 1fr;
    grid-template-rows: 3rem 1fr;
    grid-column-gap: 0;
    grid-template-areas:
      'top'
      'left'
      'left';

    > ${StyledCartView} {
      display: none;
    }
    > ${StyledProductsView} {
      display: block;
    }

    /** Show cart when user toggle cart, hide products */
    &[data-cart-visible*='true'] {
      grid-template-areas:
        'top'
        'right'
        'right';
      grid-template-columns: 1fr;

      > ${StyledCartView} {
        display: block;
      }

      > ${StyledProductsView} {
        display: none;
      }
    }
  }
`;

const StyledHeaderLogo = styled.img`
  height: calc(100% - 1rem);
  max-width: 200px;
  margin-right: 1rem;
`;

const StyledHeaderHeader = styled.h1`
  display: ${(props: Themed) =>
    props.theme.isCompanyNameHidden ? 'none' : 'inline'};
`;

const StyledViewCart = styled(StyledButton)`
  margin: 0 0 0 auto;
  padding: 0 1rem;
`;

const Store: React.FC = () => {
  const token = useToken();
  const history = useHistory();
  const { pathname } = useLocation();
  const [cartState, cartDispatch] = useCartContext();
  const { total } = getCartTotals(cartState);
  const [cartVisible, setCartVisible] = useState(false);

  useEffect(() => {
    if (!token) return;

    getCatalog(token).then(catalog => {
      cartDispatch({ type: 'catalog.update', catalog });
    });

    getStore(token)
      .then(store => {
        document.title = store.display_name || '';
        cartDispatch({ type: 'store.update', store });
      })
      .catch(error => {
        history.push(
          `/error?status=${error.status}&message=${encodeURIComponent(
            error.message
          )}`
        );
      });
  }, [token]);

  // If user hits browser back button, make sure we update context
  useEffect(() => {
    history.listen((location, action) => {
      if (location.pathname === `/${token}/` && action === 'POP') {
        cartDispatch({ type: 'shopping' });
      } else if (
        location.pathname === `/${token}/checkout` &&
        action === 'POP'
      ) {
        cartDispatch({ type: 'checkout' });
      }
    });
  }, [pathname]);

  useEffect(() => {
    // On mobile, hide the cart when navigating to checkout, or anywhere else
    setCartVisible(false);
  }, [pathname]);

  useEffect(() => {
    const numCartItems = Object.keys(cartState.items).length;

    // Open the cart when a user adds their first item, unless they're on mobile
    const mq = window.matchMedia('(max-width: 768px)');
    if (numCartItems === 1 && mq.matches === false) {
      setCartVisible(true);
    }

    // Close the cart if it's empty
    if (numCartItems <= 0) {
      setCartVisible(false);
    }
  }, [cartState]);

  return (
    <StyledStore data-path={pathname} data-cart-visible={cartVisible}>
      <StyledHeader>
        {!!cartState.store?.branding?.public_url && (
          <StyledHeaderLogo src={cartState.store?.branding?.public_url} />
        )}

        {!cartState?.store?.options?.hosted_hideCompanyName && (
          <StyledHeaderHeader>
            {cartState.store?.display_name || ''}
          </StyledHeaderHeader>
        )}

        <StyledViewCart onClick={e => setCartVisible(!cartVisible)}>
          Cart - {formatPrice(total)}
        </StyledViewCart>
      </StyledHeader>
      {cartState.mode === 'shopping' && <ProductsView />}

      <Switch>
        {Object.keys(cartState.items).length > 0 && (
          <Route path="/:token/checkout">
            <CheckoutView />
          </Route>
        )}
        <Route path="/:token/products/:id">
          <ProductDetailView
            onAddToCart={product => {
              cartDispatch({
                type: 'product.addToCart',
                product
              });
            }}
          />
        </Route>
      </Switch>
      {(cartVisible || cartState.mode === 'checkout') && <CartView />}
    </StyledStore>
  );
};

function App() {
  return (
    <ToastProvider>
      <Router>
        <Switch>
          <Route path="/error">
            <ErrorPage />
          </Route>
          <Route path="/:token">
            <TrackerContext.Provider value={{ tracker: new Tracker() }}>
              <CartContextProvider>
                <ThemeWrapper>
                  <Store />
                </ThemeWrapper>
              </CartContextProvider>
            </TrackerContext.Provider>
          </Route>
          <Route path="*">
            <Redirect to="/error" />
          </Route>
        </Switch>
      </Router>
    </ToastProvider>
  );
}

export default App;
