/**
 * debounces a function that returns a promise -- won't invoke the promise until `ms` milliseconds have passed since the debounced function was last called. useful for onChange async validators
 * @param promiseMaker: promise maker to debounce
 */
export const debounceAsync = <A, R>(
  promiseMaker: (...args: A[]) => Promise<R>,
  ms: number = 500
): ((...args: A[]) => Promise<R>) => {
  let timeout: null | ReturnType<typeof setTimeout>;

  return (...args: A[]) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    return new Promise((resolve) => {
      timeout = setTimeout(() => resolve(promiseMaker(...args)), ms);
    });
  };
};
