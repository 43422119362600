import React from 'react';
import { useCartContext } from './CartContext';
import { ProductView } from './ProductView';
import styled from 'styled-components';

export const StyledProductsView = styled.section`
  padding: 1rem;
  overflow: hidden;
  overflow-y: scroll;
`;

const StyledProductsContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;

const StyledProductsListView = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: space-evenly;
  }
`;

const StyledHeadingOne = styled.h1`
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const ProductsView: React.FC = () => {
  const [{ catalog }, cartDispatch] = useCartContext();

  return (
    <StyledProductsView>
      <StyledProductsContainer>
        <StyledHeadingOne>Products</StyledHeadingOne>
        <StyledProductsListView>
          {!catalog && <em>Loading products...</em>}
          {catalog &&
            Object.values(catalog.products).map((product, i) => {
              return (
                <ProductView
                  style={{
                    // @ts-ignore
                    '--i': i
                  }}
                  product={product}
                  key={product.id}
                  onAddToCart={product => {
                    cartDispatch({
                      type: 'product.addToCart',
                      product
                    });
                  }}
                />
              );
            })}
        </StyledProductsListView>
      </StyledProductsContainer>
    </StyledProductsView>
  );
};
