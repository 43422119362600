import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  createContext,
  useCallback,
} from 'react';
import { Tracker, TrackerData, TrackerListener } from './tracker';

export const TrackerContext = createContext<{ tracker: Tracker }>(null as any);

export function useTracking(
  id: string
): [React.RefObject<HTMLElement>, () => void] {
  const trackerContext = useContext(TrackerContext);
  if (!trackerContext) {
    throw new Error(
      'Cannot use `useTracking` hook outside of <TrackerContext.Provider>'
    );
  }

  const { tracker } = trackerContext;
  const ref = useRef<HTMLElement>(null);

  const updateRect = useCallback(
    () => tracker.update(id, ref.current || undefined),
    [tracker, id]
  );

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    tracker.update(id, ref.current);
  }, [tracker, id]);

  return [ref, updateRect];
}

export function useTracked(id: string): TrackerData | undefined {
  const trackerContext = useContext(TrackerContext);
  if (!trackerContext) {
    throw new Error(
      'Cannot use `useTracked` hook outside of <TrackerContext.Provider>'
    );
  }

  const { tracker } = trackerContext;
  const [rect, setRect] = useState<TrackerData | undefined>();

  useEffect(() => {
    const listener: TrackerListener = (data) => {
      setRect({ ...data });
    };

    tracker.listen(id, listener);

    return () => {
      tracker.unlisten(id, listener);
    };
  }, [id, tracker]);

  return rect;
}
