import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Themed } from './types';
import { defaultTheme } from './utils/theme';

export const StyledButton = styled.button`
  appearance: none;
  border: none;
  background: ${(props: Themed) => props.theme.colors.primaryColor};
  color: white;
  font-weight: bold;
  font-size: 1rem;
  height: 3rem;
  border-radius: var(--radius);
  overflow: hidden;
  cursor: pointer;
  padding: 0 1rem;

  &:focus {
    outline: 2px solid ${(props: Themed) => props.theme.colors.primaryColor};
    outline-offset: 2px;
  }

  &[data-variant='primary'] {
    background: ${(props: Themed) => props.theme.colors.primaryColor};
  }

  &[data-variant='positive'] {
    background: #32ab3b;
  }

  &[data-variant='secondary'] {
    background: ${(props: Themed) => props.theme.colors.secondaryAccent};
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
  }

  @media (max-width: 768px) {
    height: 2.2rem;
  }
`;

StyledButton.defaultProps = { theme: defaultTheme };

export const StyledButtonLink = styled(Link)`
  --color-button: var(--color-primary);
  text-align: center;
  text-decoration: none;
  border-radius: var(--radius);
  display: block;
  padding: 1rem;
  border: none;
  background: var(--color-button);
  color: white;
  font-weight: bold;
  font-size: 1rem;
`;
