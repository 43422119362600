const usd = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

export function formatPrice(price: number | string): string {
  return usd.format(+price || 0);
}

export function formatExpiration(value: string | null | undefined) {
  if (!value) return value;
  const groups = value!.replace(/\D/g, '').match(/.{1,2}/g);
  return groups ? groups.slice(0, 2).join('/') : '';
}

export function formatPhone(value: string | null | undefined) {
  if (!value) return value;

  let formattedNumber = String(value).replace(/[\D]/g, '');

  // remove non-digits
  if (formattedNumber.length <= 10) {
    const firstThree = formattedNumber.substring(0, 3);
    const secondThree = formattedNumber.substring(3, 6);
    const thirdFour = formattedNumber.substring(6, 10);

    if (firstThree) {
      formattedNumber = `(${firstThree}`;
    }

    if (secondThree) {
      formattedNumber += `) ${secondThree}`;
    }

    if (thirdFour) {
      formattedNumber += `-${thirdFour}`;
    }

    return formattedNumber;
  } else {
    // else return international format
    return `+${formattedNumber}`;
  }
}

export function formatUnknownError(error: any): string {
  let errorMessage;

  if (error && error.message) {
    errorMessage = error.message;
  } else if (error && typeof error === 'object') {
    if (error.status != undefined && error.readyState != undefined && Object.keys(error).length === 2) {
      //in this condition the exception was one with the XMLHttpRequest, so likely a network IO error
      errorMessage = "A network error has occurred with your request. Please try again later.";
    } else {
      errorMessage = Object.values(error)
        .map(msgs => (Array.isArray(msgs) ? msgs.join('\n') : msgs))
        .join('\n');
    }
  } else {
    errorMessage = error;
  }

  return errorMessage;
}

export function round(value: number, places = 2): number {
  return Math.round(value * Math.pow(10, places)) / Math.pow(10, places);
}

export function formatCapitalCase(s?: string) {
  if (!s) return s;
  const result = s.toLowerCase();
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function formatTitleCase(s?: string) {
  if (!s) return s;
  return s.split(' ').map(formatCapitalCase).join(' ');
}

export const formatAddressLine = (
  ...addressLine: (string | null | undefined)[]
) => {
  let r = addressLine.filter(v => !!v);
  if (r.length > 1) r.splice(1, 0, ', ');
  if (r.length > 3) r.splice(3, 0, ' ');
  return r.join('');
};
