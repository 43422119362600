import React, { useMemo } from 'react';
import { createContext, useContext } from 'react';
import { produce } from 'immer';
import {
  Product,
  Receipt,
  Catalog,
  Store,
  SurchargeReviewResponse,
} from './types';
import { EffectReducer, useEffectReducer } from 'use-effect-reducer';
import { useHistory } from 'react-router';
import {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
} from './useLocalStorage';
import { useToken } from './useToken';
import { round } from './utils/format';
import { useToaster } from './toast';

export const CART_ITEM_MIN_QUANTITY = 1;

export interface CartItem {
  product: Product;
  quantity: number;
}

export interface CartItems {
  [key: string]: CartItem;
}

export interface CartState {
  store: Store | null;
  catalog: Catalog | null;
  mode: 'shopping' | 'checkout' | 'review';
  items: CartItems;
  discounts: CartItems;
  receipt: Receipt | null;
  surchargeReviewData: SurchargeReviewResponse | null;
}

export type CartEvent =
  | {
      type: 'product.addToCart';
      product: Product;
    }
  | {
      type: 'product.delete';
      productId: string;
    }
  | {
      type: 'product.quantity';
      productId: string;
      quantity: number;
    }
  | { type: 'shopping' }
  | { type: 'checkout' }
  | { type: 'digitalwallet'; values?: any }
  | { type: 'review' }
  | { type: 'receipt'; receipt: Receipt }
  | {
      type: 'catalog.update';
      catalog: Catalog;
    }
  | {
      type: 'store.update';
      store: Store;
    }
  | {
      type: 'surchargeReviewData.update';
      surchargeReviewData: SurchargeReviewResponse;
    }
  | { type: 'clear' };

export const CartContext = createContext<
  [CartState, React.Dispatch<CartEvent>]
>(null as any);

const cartReducer: EffectReducer<CartState, CartEvent> = (
  state,
  event,
  exec
) => {
  const nextState = produce(state, draft => {
    const disabled = !!draft.surchargeReviewData;

    switch (event.type) {
      case 'product.addToCart':
        // don't allow editing items if disabled
        if (disabled) break;

        if (event.product.is_discount) {
          const shouldAddDiscount =
            // Don't allow the user to add multiples of the same discount
            !draft.discounts[event.product.id] &&
            // TODO: determine whether or not we want to support multiple discounts
            Object.keys(draft.discounts).length === 0;

          if (shouldAddDiscount) {
            draft.discounts[event.product.id] = {
              product: event.product,
              quantity: 1,
            };
          }
        } else if (!draft.items[event.product.id]) {
          draft.items[event.product.id] = {
            product: event.product,
            quantity: CART_ITEM_MIN_QUANTITY,
          };
        } else if (event.product.is_service) {
          draft.items[event.product.id].quantity++;
        } else if (
          draft.items[event.product.id].quantity >= event.product.in_stock
        ) {
          exec({
            type: 'notify',
            msg: `Only ${event.product.in_stock} available`,
            toastType: 'warning',
          });
        } else if (
          draft.items[event.product.id].quantity < event.product.in_stock
        ) {
          draft.items[event.product.id].quantity++;
        }
        break;
      case 'product.quantity':
        // don't allow editing items if disabled
        if (disabled) break;

        if (draft.items[event.productId]) {
          if (
            event.quantity !== draft.items[event.productId].quantity &&
            event.quantity > draft.items[event.productId].product.in_stock
          ) {
            exec({
              type: 'notify',
              msg: `Only ${
                draft.items[event.productId].product.in_stock
              } available`,
              toastType: 'warning',
            });
          }

          if (draft.items[event.productId].product.in_stock !== undefined) {
            draft.items[event.productId].quantity = Math.max(
              Math.min(
                event.quantity,
                Number(draft.items[event.productId].product.in_stock)
              ),
              CART_ITEM_MIN_QUANTITY
            );
          } else {
            draft.items[event.productId].quantity = event.quantity;
          }
        }

        break;
      case 'product.delete':
        // don't allow editing items if disabled
        if (disabled) break;

        delete draft.items[event.productId];
        delete draft.discounts[event.productId];
        // If all cart items are deleted, return to shopping
        if (Object.keys(draft.items).length <= 0) {
          exec({ type: 'navigate', to: 'shopping' });
          draft.mode = 'shopping';
        }
        break;
      case 'checkout':
        if (Object.keys(draft.items).length > 0) {
          exec({ type: 'navigate', to: 'checkout' });
          draft.surchargeReviewData = null;
          draft.mode = 'checkout';
        }
        break;
      case 'shopping':
        exec({ type: 'navigate', to: '' });
        draft.mode = 'shopping';
        break;
      case 'receipt':
        draft.receipt = event.receipt;
        break;
      case 'catalog.update':
        draft.catalog = event.catalog;
        break;
      case 'store.update':
        draft.store = event.store;
        break;
      case 'surchargeReviewData.update':
        draft.surchargeReviewData = event.surchargeReviewData;
        break;
      case 'clear':
        draft.items = {} as CartItems;
        draft.receipt = null;
        draft.mode = 'shopping';
        draft.surchargeReviewData = null;
        exec({ type: 'clear' });
        break;
      default:
        break;
    }
  });

  if (event.type !== 'receipt') {
    exec({ type: 'persist' });
  } else {
    exec({ type: 'clear' });
  }

  return nextState;
};

const initialCartState: CartState = {
  mode: 'shopping',
  items: {},
  discounts: {},
  receipt: null,
  catalog: null,
  store: null,
  surchargeReviewData: null,
};

const OMNICART_LOCALSTORAGE_KEY = 'omnicart';

export const CartContextProvider: React.FC = ({ children }) => {
  const token = useToken();
  const history = useHistory();
  const { toaster, toast } = useToaster();
  // suffixing local storage key with .{merchant.hosted_payments_token} here
  // so that we can store carts per-merchant
  const localStorageKey = `${OMNICART_LOCALSTORAGE_KEY}.${token}`;

  const initialState: CartState = useMemo(() => {
    return {
      ...initialCartState,
      ...getLocalStorage(localStorageKey, initialCartState),
      // do not persist receipt data
      receipt: null,
      // do not persist surcharge data
      surchargeReviewData: null,
    };
  }, [localStorageKey]);

  const [state, dispatch] = useEffectReducer(cartReducer, initialState, {
    navigate: (_, effect) => {
      history.push(`/${token}/${effect.to}`);
    },
    persist: state => {
      setLocalStorage(localStorageKey, state);
    },
    clear: () => {
      removeLocalStorage(localStorageKey);
    },
    notify: (_, effect) => {
      const type: 'success' | 'info' | 'warning' | 'error' = effect.toastType;
      toaster(toast[type](effect.msg));
    },
  });

  return <CartContext.Provider value={[state, dispatch]} children={children} />;
};

export const useCartContext = () => {
  return useContext(CartContext);
};

const getItemPrice = (item: CartItem) =>
  Number.isNaN(+item.product.price) ? 0 : +item.product.price;

export const getTotalOfItems = (cartItems: CartItems): number => {
  return Object.keys(cartItems).reduce((sum: number, itemKey: string) => {
    const item = cartItems[itemKey];
    const total = getItemPrice(item) * item.quantity;
    return sum + total;
  }, 0);
};

export const getTotalOfItemsAfterPercentageDiscounts = (
  cartItems: CartItems,
  percentageDiscountMultipliers: number[]
) => {
  const multipliers = percentageDiscountMultipliers;
  const products = Object.keys(cartItems).map(itemKey => {
    return cartItems[itemKey];
  });

  return products.reduce((sum: number, item: CartItem) => {
    const total = getItemPrice(item) * item.quantity;

    const isDiscountable =
      typeof item.product.meta.is_discountable === 'undefined' ||
      item.product.meta.is_discountable;

    if (!isDiscountable || total <= 0) {
      return sum + total;
    }

    return (
      sum + multipliers.reduce((sum, multiplier) => sum * multiplier, total)
    );
  }, 0);
};

export const getEcommerceTaxRate = (cartState: CartState): number => {
  const ecommerceTaxRate = cartState.store?.options?.ecommerce_tax_rate;

  if (!ecommerceTaxRate) return 0;

  return Math.round(1000 * ecommerceTaxRate) / 1000;
};

export const getCartTotals = (
  cartState: CartState
): {
  discountBeforeTax: number;
  discountAfterTax: number;
  subTotal: number;
  tax: number;
  total: number;
  surcharge: number | null;
} => {
  const cartItems = cartState.items;
  const cartDiscounts = cartState.discounts;

  const taxableItems: CartItems = {};
  const nonTaxableItems: CartItems = {};
  const taxableFlatDiscounts: CartItems = {};
  const nonTaxableFlatDiscounts: CartItems = {};
  const percentageDiscounts: CartItems = {};

  Object.keys(cartDiscounts).forEach(cartItemKey => {
    const cartItem = cartDiscounts[cartItemKey];
    const { product } = cartItem;
    const isPercentage: boolean = product.meta.is_percentage || false;

    if (isPercentage) {
      percentageDiscounts[cartItemKey] = cartItem;
    } else if (+product.price < 0 || !isPercentage) {
      if (product.is_taxable) {
        taxableFlatDiscounts[cartItemKey] = cartItem;
      } else {
        nonTaxableFlatDiscounts[cartItemKey] = cartItem;
      }
    }
  });

  Object.keys(cartItems).forEach(cartItemKey => {
    const cartItem = cartItems[cartItemKey];
    const { product } = cartItem;

    if (product.is_taxable) {
      taxableItems[cartItemKey] = cartItem;
    } else {
      nonTaxableItems[cartItemKey] = cartItem;
    }
  });

  const taxableFlatDiscount = Object.keys(taxableFlatDiscounts).reduce(
    (sum, itemKey) => {
      const item = taxableFlatDiscounts[itemKey];
      return sum + item.quantity * Math.abs(getItemPrice(item));
    },
    0
  );

  const nonTaxableFlatDiscount = Object.keys(nonTaxableFlatDiscounts).reduce(
    (sum, itemKey) => {
      const item = nonTaxableFlatDiscounts[itemKey];
      return sum + item.quantity * Math.abs(getItemPrice(item));
    },
    0
  );

  const percentageDiscountMultipliers: number[] = Object.keys(
    percentageDiscounts
  ).reduce((allMultipliers: number[], discountKey) => {
    const discount = percentageDiscounts[discountKey];

    const total = getItemPrice(discount) * discount.quantity;

    // if total is 0, (0% discount) there's no reason to add it
    const shouldNotAdd = total === 0;
    if (shouldNotAdd) return allMultipliers;

    // what the item price will be multiplied by to produce the after-discount price
    // e.g. 20% discount becomes 0.8 multiplier
    const multiplier = (100 - total) / 100;

    // return accumulated multipliers + the new one we just made
    return allMultipliers.concat([multiplier]);
  }, []);

  const baseSubtotal = getTotalOfItems(
    Object.assign({}, taxableItems, nonTaxableItems)
  );

  const taxableSubtotal = getTotalOfItems(taxableItems);
  let discountedTaxableSubtotal = getTotalOfItemsAfterPercentageDiscounts(
    taxableItems,
    percentageDiscountMultipliers
  );

  const taxablePercentageDiscount = taxableSubtotal - discountedTaxableSubtotal;
  discountedTaxableSubtotal -= taxableFlatDiscount;

  const nonTaxableSubtotal = getTotalOfItems(nonTaxableItems);
  let discountedNonTaxableSubtotal = getTotalOfItemsAfterPercentageDiscounts(
    nonTaxableItems,
    percentageDiscountMultipliers
  );

  const nonTaxablePercentageDiscount =
    nonTaxableSubtotal - discountedNonTaxableSubtotal;

  const calculatedTax = round(
    Math.max(discountedTaxableSubtotal, 0) *
      0.01 *
      getEcommerceTaxRate(cartState)
  );

  const tax = Number(calculatedTax.toFixed(2));
  const surcharge = cartState.surchargeReviewData?.surcharge_amount ?? 0;

  discountedTaxableSubtotal -= nonTaxableFlatDiscount;

  const total =
    discountedTaxableSubtotal + discountedNonTaxableSubtotal + tax + surcharge;

  return {
    discountBeforeTax: round(
      Number((taxableFlatDiscount + taxablePercentageDiscount).toFixed(2))
    ),
    discountAfterTax: round(
      Number((nonTaxableFlatDiscount + nonTaxablePercentageDiscount).toFixed(2))
    ),
    subTotal: round(Number(baseSubtotal.toFixed(2))),
    tax: round(Number(tax.toFixed(2))),
    total: round(Number(total.toFixed(2))),
    surcharge: cartState.surchargeReviewData ? surcharge : null,
  };
};
