import React, { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';
import {
  formatCapitalCase,
  formatPhone,
  formatAddressLine
} from './utils/format';

import { Customer, PaymentMethod } from '@fattmerchantorg/types-omni';
import { SurchargeReviewResponse } from './types';
import { CheckoutState } from './checkoutReducer';

type StyledComponent<T, H = HTMLAttributes<T>> = FunctionComponent<H>;

const Container = styled.div`
  animation: fade-in 0.2s ease-in-out both, shift-up 0.3s ease-in-out both;
`;

const Subtitle: StyledComponent<HTMLHeadingElement> = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  border-bottom: 1px solid #000000;
`;

const Section: StyledComponent<HTMLDivElement> = styled.div`
  padding: 16px 0;

  h4,
  p {
    margin: 0 0 8px 0;
    padding: 0 0 8px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Text: StyledComponent<HTMLDivElement> = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 0 8px 0;
`;

export type SurchargeReviewProps = Pick<
  CheckoutState,
  'formValues' | 'sameAsBilling'
> & {
  surchargeReviewData?: Partial<SurchargeReviewResponse>;
  paymentMethod: PaymentMethod & { customer?: Customer };

  // Amounts.
  total: number;
  tip?: number;
};

export const SurchargeReview: FunctionComponent<SurchargeReviewProps> =
  props => {
    const { surchargeReviewData, paymentMethod, formValues, sameAsBilling } =
      props;

    if (!surchargeReviewData) return null;

    return (
      <Container>
        {paymentMethod.method === 'card' ? (
          <Section>
            <Subtitle>Payment Method</Subtitle>
            <TitledDetail
              title="Card Holder Name"
              text={`${formValues.firstname} ${formValues.lastname}`}
            />
            <TitledDetail
              title="Card"
              text={`**** **** **** ${paymentMethod.card_last_four}`}
            />
            {/* The expiration date is stored as `MMYYYY` */}
            <TitledDetail
              title="Expiration Date"
              text={formatSpreedlyDate(paymentMethod.card_exp)}
            />
          </Section>
        ) : null}

        {paymentMethod.method === 'bank' ? (
          <Section>
            <Subtitle>Payment Method</Subtitle>
            <TitledDetail
              title="Account"
              text={`**** **** **** ${paymentMethod.card_last_four}`}
            />
            <TitledDetail
              title="Account Type"
              text={formatCapitalCase(paymentMethod.bank_type)}
            />
            <TitledDetail
              title="Bank Holder"
              text={formatCapitalCase(paymentMethod.bank_holder_type)}
            />
          </Section>
        ) : null}

        {formValues.firstname ? (
          <Section>
            <Subtitle>Contact Info</Subtitle>
            <Text>{formValues.email}</Text>
            <Text style={{ marginTop: '16px' }}>
              {formatPhone(formValues.phone)}
            </Text>
          </Section>
        ) : null}

        <Section>
          <Subtitle>Billing Address</Subtitle>
          <Text>
            {formatAddressLine(formValues.address_1, formValues.address_2)}
          </Text>
          <Text>
            {formatAddressLine(
              formValues.address_city,
              formValues.address_state,
              formValues.address_zip
            )}
          </Text>
        </Section>
        <Section>
          <Subtitle>Shipping Address</Subtitle>
          {sameAsBilling ? (
            <Text>Same as Billing Address</Text>
          ) : (
            <>
              <Text>
                {formatAddressLine(
                  formValues.shipping_address_1,
                  formValues.shipping_address_2
                )}
              </Text>
              <Text>
                {formatAddressLine(
                  formValues.shipping_address_city,
                  formValues.shipping_address_state,
                  formValues.shipping_address_zip
                )}
              </Text>
            </>
          )}
        </Section>
      </Container>
    );
  };

const TitledDetail: FunctionComponent<{
  title?: string;
  text?: string;
}> = ({ title, text }) => {
  return (
    <>
      <h4>{title}</h4>
      <p>{text}</p>
    </>
  );
};

const formatSpreedlyDate = (monthYearDate: string) => {
  if (!monthYearDate) return '';
  return monthYearDate.substr(0, 2) + '/' + monthYearDate.substr(2, 4);
};
