import { Catalog, Product, Store } from '../types';

const queryApiUrl = process.env.REACT_APP_QUERY_API_URL;

export async function getCatalog(token: string): Promise<Catalog> {
  const response = await fetch(`${queryApiUrl}/store/${token}/catalog`);
  const products: Product[] = await response.json();
  const catalog: Catalog = {
    products: products.reduce((acc: Catalog['products'], product: Product) => {
      // Fattquery is not returning stock qty if its zero :/
      if (
        !product.is_discount &&
        !product.is_service &&
        product.in_stock === undefined
      ) {
        product.in_stock = 0;
      }
      acc[product.id] = product;
      return acc;
    }, {})
  };
  return catalog;
}

export async function getStore(token: string): Promise<Store> {
  const response = await fetch(`${queryApiUrl}/store/${token}`);
  const json = await response.json();

  if (response.ok) {
    return json as Store;
  } else {
    throw json;
  }
}

export async function getDiscount(
  token: string,
  code: string
): Promise<Product> {
  const response = await fetch(
    `${queryApiUrl}/store/${token}/discount/${code}`
  );
  const json = await response.json();

  if (response.ok) {
    return json as Product;
  } else {
    throw json;
  }
}
