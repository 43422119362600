export const convertBrandToTheme = (brand: any) => {
  return {
    logoUrl: '',
    isFattmerchantLogoHidden: false,
    isCompanyNameHidden: false,
    colors: {
      primaryColor: '#' + brand.primaryColor,
      sidebarText: '#' + brand.sidebarText,
      tertiaryColor: '#' + brand.tertiaryColor,
      accentText: '#' + brand.accentText,
      headerAccentText: '#' + brand.headerAccentText,
      sidebarActiveTab: '#' + brand.sidebarActiveTab,
      hyperlink: '#' + brand.hyperlink,
      primaryAccent: '#' + brand.primaryAccent,
      secondaryColor: '#' + brand.secondaryColor,
      secondaryAccent: '#' + brand.secondaryAccent,
      sidebarBackground: '#' + brand.sidebarBackground
    },
    fonts: {
      body: 'inherit',
      heading: 'inherit',
      monospace: 'inherit'
    },
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
    fontWeights: {
      body: 400,
      heading: 700,
      bold: 700
    },
    lineHeights: {
      body: 1.5,
      heading: 1.25
    },
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    sizes: {
      avatar: 48
    },
    radii: {
      default: 4,
      circle: 99999
    },
    shadows: {
      card: '0 0 4px rgba(0, 0, 0, .125)'
    },
    text: {
      heading: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading'
      },
      display: {
        fontFamily: 'body',
        fontWeight: 'body',
        lineHeight: 'body',
        fontSize: [5, 6, 7]
      },
      caps: {
        textTransform: 'uppercase',
        letterSpacing: '0.1em'
      }
    },
    variants: {
      avatar: {
        width: 'avatar',
        height: 'avatar',
        borderRadius: 'circle'
      },
      card: {
        p: 2,
        bg: 'background',
        boxShadow: 'card'
      },
      link: {
        color: 'primary'
      },
      nav: {
        fontSize: 1,
        fontWeight: 'bold',
        display: 'inline-block',
        p: 2,
        color: 'inherit',
        textDecoration: 'none',
        ':hover,:focus,.active': {
          color: 'primary'
        }
      }
    },
    buttons: {
      primary: {
        fontSize: 2,
        fontWeight: 'bold',
        color: 'background',
        bg: '#' + brand.primaryAccent,
        borderRadius: 'default'
      },
      outline: {
        variant: 'buttons.primary',
        color: 'primary',
        bg: 'transparent',
        boxShadow: 'inset 0 0 2px'
      },
      secondary: {
        variant: 'buttons.primary',
        color: 'background',
        bg: 'secondary'
      }
    },
    styles: {
      root: {
        fontFamily: 'body',
        fontWeight: 'body',
        lineHeight: 'body'
      }
    }
  };
};

export const defaultTheme = convertBrandToTheme({
  primaryColor: '#B93BE4'.replace('#', ''),
  secondaryAccent: '#B93BE4'.replace('#', ''),
  sidebarBackground: '#062333'.replace('#', '')
});
