import React, { useRef, useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { StyledButton } from './Button';
import { useEffectReducer } from 'use-effect-reducer';
import { useCartContext, getCartTotals } from './CartContext';
import { formatPrice, formatUnknownError } from './utils/format';
import { ReceiptView } from './ReceiptView';
import { Receipt, FattJsInstance } from './types';
import {
  CheckoutState,
  checkoutReducer,
  checkoutReducerInitialState,
  CheckoutFormValues
} from './checkoutReducer';
import { useToken } from './useToken';
import { loadFattmerchantJs } from './utils/fattmerchantJsLoader';
import { useToaster } from './toast';
import { Themed } from './types';
import { SurchargeReview } from './SurchargeReviewView';

const DigitalWalletsPaymentButtonContainer = styled.div`
  padding: 8px 0;
`;

const HrWithText = styled.div`
  & {
    display: flex;
    align-items: center;
    text-align: center;
    color: #555;
    font-size: 80%;
    opacity: 0.8;
  }

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #555;
  }

  &:not(:empty)::before {
    margin-right: 0.25em;
  }

  &:not(:empty)::after {
    margin-left: 0.25em;
  }
`;

const ReCaptchaTerms = styled.div`
  color: #bdc9cc;
  text-align: center;
  padding-top: 1rem;

  a {
    color: #66cccc;
  }
`;

const StyledCheckoutDiv = styled.div`
  height: 35px;
`;

const StyledCheckoutInput = styled.input`
  appearance: none;
  border: none;
  background: transparent;
  height: 35px;
  font-size: 1rem;

  &:focus {
    outline: none;
  }
`;

const StyledCheckoutError = styled.span`
  font-size: 12px;
  color: red;
`;

const StyledCheckoutField = styled.div`
  display: grid;
  grid-template-rows: 1rem auto;
  grid-row-gap: 0.5rem;

  input {
    border-bottom: 2px solid #ddd;
    width: 100%;
  }

  > label {
    font-weight: bold;
  }
`;

const StyledCardView = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  grid-template-rows: auto 1fr 1fr;
  grid-template-areas:
    'header header logo'
    'number number number'
    'name expiry cvv';
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  border-radius: var(--radius);
  border: 2px solid var(--color-border);
  padding: 1rem;

  > header {
    grid-area: header;
  }

  > figure {
    grid-area: logo;
  }

  > [data-field='number'] {
    grid-area: number;
  }

  > [data-field='expiry'] {
    grid-area: expiry;
  }

  > [data-field='cvv'] {
    grid-area: cvv;
  }

  > [data-field='name'] {
    grid-area: name;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas:
      'header logo'
      'number number'
      'name name'
      'expiry cvv';
  }
`;

const StyledInfoView = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  > header {
    grid-area: header;
    display: flex;
    flex-direction: row;
    align-items: center;

    > label {
      margin-left: 1rem;
    }
  }
`;

const StyledUserView = styled(StyledInfoView)`
  grid-template-areas:
    'email email email'
    'phone phone phone';

  > [data-field='phone'] {
    grid-area: phone;
  }

  > [data-field='email'] {
    grid-area: email;
  }
`;

const StyledAddressView = styled(StyledInfoView)`
  grid-template-areas:
    'header header header'
    'address1 address1 address2'
    'city state zip';

  > [data-field='address1'] {
    grid-area: address1;
  }

  > [data-field='address2'] {
    grid-area: address2;
  }

  > [data-field='city'] {
    grid-area: city;
  }

  > [data-field='state'] {
    grid-area: state;
  }

  > [data-field='zip'] {
    grid-area: zip;
  }
`;

const StyledCheckoutFooter = styled.footer`
  > button {
    width: 100%;
  }
`;

const StyledCheckoutDetails = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 1rem;
  overflow-y: auto;
`;

const StyledCheckboxField = styled.label`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 0.5rem;

  > * {
    align-self: center;
  }
`;

export const StyledCheckoutView = styled.form`
  background: white;
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  gap: 1rem;
  grid-template-areas:
    'header'
    'details'
    'footer';

  > ${StyledCheckoutDetails} {
    grid-area: details;
    padding: 0 2px;
  }

  > ${StyledCheckoutFooter} {
    grid-area: footer;
  }

  &[data-state='idle'],
  &[data-state='review'] {
    ${StyledButton} {
      > :not(:first-child) {
        transform: translateY(100%);
      }
    }
  }

  &[data-state='paying'],
  &[data-state='tokenizing'],
  &[data-state='success'] {
    > ${StyledCheckoutDetails} {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &[data-state='paying'],
  &[data-state='tokenizing'] {
    > ${StyledCheckoutFooter} {
      opacity: 0.5;
    }

    ${StyledButton} {
      > * {
        transform: translateY(-100%);
      }
      > :nth-child(2) {
        transform: none;

        ~ * {
          transform: translateY(100%);
        }
      }
    }
  }

  &[data-state='success'] {
    ${StyledButton} {
      > * {
        transform: translateY(-100%);
      }
      > :nth-child(3) {
        transform: none;

        ~ * {
          transform: translateY(100%);
        }
      }
    }
  }
`;

const StyledBackButton = styled.button`
  display: flex;
  place-items: center;
  padding: 0;
  height: auto;
  border: 0;
  font-size: 1rem;
  margin-bottom: 1rem;
  background: none;
  appearance: none;
  cursor: pointer;
  color: ${(props: Themed) => props.theme.colors.primaryColor};

  &:before {
    display: block;
    content: '';
    width: 0;
    height: 0;
    margin: 0 0.5rem 0 0;
    border-style: solid;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-color: transparent
      ${(props: Themed) => props.theme.colors.primaryColor} transparent
      transparent;
  }
`;

const CheckoutField: React.FC<{
  label: string;
  name?: keyof CheckoutFormValues;
  state?: CheckoutState; // todo: put this in a context instead of having a prop
}> = props => {
  const { label, name, state, children, ...other } = props;
  const error = name && state && state.formErrors[name];

  return (
    <StyledCheckoutField {...other}>
      <label>{label}</label>
      {children}
      <StyledCheckoutError>{error}</StyledCheckoutError>
    </StyledCheckoutField>
  );
};

export const CheckoutView: React.FC = () => {
  const token = useToken();
  const history = useHistory();
  const location = useLocation();
  const [cartState, cartDispatch] = useCartContext();
  const { toaster, toast } = useToaster();
  const fattJsRef = useRef<FattJsInstance>();
  const isSurchargeEnabled = cartState?.store?.is_surcharge_enabled;
  const isDigitalWalletEnabled =
    (cartState.store?.is_cnp_digital_wallet_enabled ?? false) &&
    (cartState.store?.options
      ?.are_cnp_digital_wallet_payments_allowed_by_default ??
      true);

  const [state, dispatch] = useEffectReducer<CheckoutState, any>(
    checkoutReducer,
    checkoutReducerInitialState,
    {
      tokenize: (_, effect, dispatch) => {
        const formValues: CheckoutFormValues = effect.values;
        const cartTotals = getCartTotals(cartState);

        (async () => {
          try {
            const paymentMethod = await fattJsRef.current!.tokenize({
              month: formValues.month,
              year: formValues.year,

              firstname: formValues.firstname,
              lastname: formValues.lastname,
              phone: formValues.phone?.replace(/[\D]/g, ''),
              email: formValues.email,

              address_1: formValues.address_1,
              address_2: formValues.address_2,
              address_city: formValues.address_city,
              address_state: formValues.address_state,
              address_zip: formValues.address_zip
            });

            // if surcharge isn't enabled, just pay immediately
            if (!isSurchargeEnabled) {
              return dispatch({ type: 'pay', value: paymentMethod });
            }

            // otherwise, calculate surcharge, save it to cart state, and navigate to surcharge review page
            const surchargeReviewData =
              await fattJsRef.current!.reviewSurcharge(
                paymentMethod.id,
                cartTotals.total
              );

            cartDispatch({
              type: 'surchargeReviewData.update',
              surchargeReviewData
            });
            dispatch({ type: 'review', value: paymentMethod });
          } catch (error) {
            toaster(toast.error(formatUnknownError(error), 'Error'));
            dispatch({ type: 'tokenize.failure' });
          }
        })();
      },
      review: () => {
        history.push(`/${token}/checkout/review`);
      },
      pay: (state, effect, dispatch) => {
        const paymentMethodId = state.paymentMethod?.id;
        const formValues: CheckoutFormValues = effect.values;
        const cartTotals = getCartTotals(cartState);
        // surcharge is calculated by the core api; we should not include it in the total
        const total = cartTotals.total - (cartTotals.surcharge || 0);
        let packet = {
          month: formValues.month,
          year: formValues.year,
          payment_method_id: paymentMethodId,

          firstname: formValues.firstname,
          lastname: formValues.lastname,
          phone: formValues.phone?.replace(/[\D]/g, ''),
          email: formValues.email,

          address_1: formValues.address_1,
          address_2: formValues.address_2,
          address_city: formValues.address_city,
          address_state: formValues.address_state,
          address_zip: formValues.address_zip,

          total: total,
          url: 'https://omni.fattmerchant.com/#/bill/',
          method: 'card',

          channel: 'omnicart',

          meta: {
            shipping: {
              address_1: formValues.shipping_address_1,
              address_2: formValues.shipping_address_2,
              address_city: formValues.shipping_address_city,
              address_state: formValues.shipping_address_state,
              address_zip: formValues.shipping_address_zip
            },
            tax: cartTotals.tax,
            shippingAmount: 0,
            discountBeforeTax: cartTotals.discountBeforeTax,
            discountAfterTax: cartTotals.discountAfterTax,
            subtotal: cartTotals.subTotal,
            lineItems: Object.values(
              Object.assign({}, cartState.items, cartState.discounts)
            ).map(item => {
              return {
                id: item.product.id,
                item: item.product.item,
                quantity: item.quantity,
                price: item.product.price,
                is_discount: !!item.product.is_discount,
                meta: item.product.meta
              };
            })
          }
        };
        if (
          formValues.is_digital_wallet &&
          formValues.is_digital_wallet === true
        ) {
          packet = { ...packet, ...formValues };
          delete packet.payment_method_id;
        }

        fattJsRef.current!.pay(packet).then(
          (receipt: Receipt) => {
            dispatch({ type: 'payment.success', receipt });
          },
          (error: any) => {
            let errorMessage;
            // if the error has an id, it's a failed transaction
            // and the error message will be in the .payment_attempt_message property
            if (error.id) {
              errorMessage = error.payment_attempt_message;
            } else {
              errorMessage = formatUnknownError(error);
            }
            toaster(toast.error(errorMessage, 'Error'));
            dispatch({ type: 'payment.failure' });
          }
        );
      },
      sendReceipt: (_, effect) => {
        localStorage.clear();
        cartDispatch({
          type: 'receipt',
          receipt: effect.receipt
        });
      }
    }
  );

  useEffect(() => {
    const cartTotals = getCartTotals(cartState);
    const total = cartTotals.total - (cartTotals.surcharge || 0);
    if (fattJsRef && fattJsRef.current) {
      fattJsRef.current!.setPrice(total);
    }
  }, [cartState]);

  useEffect(() => {
    // don't load fattjs if there is somehow no token
    if (!token) return;
    // don't load fattjs if we're on the review step
    if (location.pathname.includes('review')) return;

    loadFattmerchantJs().then(() => {
      const FattJs = (window as any).FattJs;
      const fattJs: FattJsInstance = new FattJs(token, {
        reCAPTCHATermsElementID: 'reCAPTCHA-terms',
        number: {
          id: 'card-number',
          placeholder: '0000 0000 0000 0000',
          style:
            'line-height: 30px; width: 100%; font-size: 16px; color: #31325f; font-weight: 400; border-bottom: 2px solid #ddd;'
        },
        cvv: {
          id: 'card-cvv',
          placeholder: 'CVV',
          style:
            'line-height: 30px; width: 100%; font-size: 16px; color: #31325f; font-weight: 400; border-bottom: 2px solid #ddd;'
        }
      });

      fattJs.showCardForm();
      if (!isSurchargeEnabled && isDigitalWalletEnabled) {
        fattJs.on('digitalwallet_initialized', () => {
          const cartTotals = getCartTotals(cartState);
          const total = cartTotals.total - (cartTotals.surcharge || 0);
          fattJs.setPrice(total);
          const element = document.getElementById(
            'digital-wallet-payments-container'
          );
          if (element) {
            element?.setAttribute('style', 'display:block');
          }
        });
        //Mimic clicking "submit"
        fattJs.on('digitalwallet_tokenize', details => {
          let packet = {
            ...state.formValues,
            ...details,
            is_digital_wallet: true
          };
          dispatch({ type: 'digitalwallet', values: packet });
        });
      }
      fattJs.on('card_form_number_change', cardFormStatus => {
        dispatch({
          type: 'formErrors.merge',
          value: {
            card_number: cardFormStatus.validNumber
              ? null
              : 'Please enter a valid number'
          }
        });
      });
      fattJs.on('card_form_cvv_change', cardFormStatus => {
        dispatch({
          type: 'formErrors.merge',
          value: {
            card_cvv: cardFormStatus.validCvv
              ? null
              : 'Please enter a valid cvv'
          }
        });
      });
      fattJsRef.current = fattJs;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, location.pathname]);

  useEffect(() => {
    // if user navigates directly to the review route without any surcharge data
    // then kick them back to the checkout page
    if (
      location.pathname.includes('review') &&
      !cartState.surchargeReviewData
    ) {
      history.replace(`/${token}/checkout`);
    }
  }, [token, history, location.pathname, cartState.surchargeReviewData]);

  return (
    <StyledCheckoutView
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();

        e.persist();
        dispatch({ type: 'submit' });
      }}
      data-state={state.status}
    >
      <Switch>
        <Route exact path="/:token/checkout">
          <header>
            <StyledBackButton
              type="button"
              data-variant="secondary"
              onClick={() => cartDispatch({ type: 'shopping' })}
            >
              Continue Shopping
            </StyledBackButton>
            <h1>Checkout</h1>
          </header>

          <StyledCheckoutDetails>
            {isDigitalWalletEnabled && !isSurchargeEnabled ? (
              <div
                id="digital-wallet-payments-container"
                data-testid="digital-wallet-payments-container"
                style={{ display: 'none' }}
              >
                <DigitalWalletsPaymentButtonContainer>
                  <div id="pay-with-apple"></div>
                  <div id="pay-with-google"></div>
                </DigitalWalletsPaymentButtonContainer>
                <HrWithText>Or enter a new credit card</HrWithText>
              </div>
            ) : null}
            <StyledCardView>
              <header></header>
              <figure></figure>
              <CheckoutField
                label="Card Number"
                data-field="number"
                name={'card_number' as any}
                state={state}
              >
                <StyledCheckoutDiv id="card-number" />
              </CheckoutField>
              <CheckoutField label="Cardholder Name" data-field="name">
                <StyledCheckoutInput
                  name="cc-name"
                  placeholder="Jane Doe"
                  autoComplete="cc-name"
                  onChange={e => {
                    dispatch({
                      type: 'name.update',
                      value: e.target.value
                    });
                  }}
                />
              </CheckoutField>
              <CheckoutField label="Expiration" data-field="exp">
                <StyledCheckoutInput
                  name="cc-exp"
                  data-field="expiry"
                  placeholder="MM/YY"
                  autoComplete="cc-exp"
                  value={state.formValues.expiry}
                  onChange={e => {
                    dispatch({
                      type: 'expiry.update',
                      value: e.target.value
                    });
                  }}
                />
              </CheckoutField>
              <CheckoutField
                label="CVV"
                data-field="cvv"
                name={'card_cvv' as any}
                state={state}
              >
                <StyledCheckoutDiv id="card-cvv" />
              </CheckoutField>
            </StyledCardView>

            <StyledUserView>
              <CheckoutField label="Email Address" data-field="email">
                <StyledCheckoutInput
                  type="email"
                  autoComplete="email"
                  value={state.formValues.email}
                  onChange={e => {
                    dispatch({
                      type: 'email.update',
                      value: e.target.value
                    });
                  }}
                  required
                />
              </CheckoutField>

              <CheckoutField label="Phone" data-field="phone">
                <StyledCheckoutInput
                  type="tel"
                  autoComplete="tel"
                  value={state.formValues.phone}
                  onChange={e => {
                    dispatch({
                      type: 'phone.update',
                      value: e.target.value
                    });
                  }}
                  required
                />
              </CheckoutField>
            </StyledUserView>

            <StyledAddressView>
              <header>
                <h2>Billing</h2>
              </header>
              <CheckoutField label="Address" data-field="address1">
                <StyledCheckoutInput
                  value={state.formValues.address_1}
                  autoComplete="billing street-address"
                  onChange={e => {
                    dispatch({
                      type: 'address_1.update',
                      value: e.target.value
                    });
                  }}
                  required
                />
              </CheckoutField>
              <CheckoutField label="Address Line 2" data-field="address2">
                <StyledCheckoutInput
                  value={state.formValues.address_2}
                  autoComplete="billing street-address 2"
                  onChange={e => {
                    dispatch({
                      type: 'address_2.update',
                      value: e.target.value
                    });
                  }}
                />
              </CheckoutField>
              <CheckoutField label="City" data-field="city">
                <StyledCheckoutInput
                  value={state.formValues.address_city}
                  autoComplete="billing locality"
                  onChange={e => {
                    dispatch({
                      type: 'address_city.update',
                      value: e.target.value
                    });
                  }}
                  required
                />
              </CheckoutField>
              <CheckoutField label="State" data-field="state">
                <StyledCheckoutInput
                  maxLength={2}
                  value={state.formValues.address_state}
                  autoComplete="billing region"
                  onChange={e => {
                    dispatch({
                      type: 'address_state.update',
                      value: e.target.value
                    });
                  }}
                  required
                />
              </CheckoutField>
              <CheckoutField label="Zip" data-field="zip">
                <StyledCheckoutInput
                  value={state.formValues.address_zip}
                  autoComplete="billing postal-code"
                  onChange={e => {
                    dispatch({
                      type: 'address_zip.update',
                      value: e.target.value
                    });
                  }}
                  required
                />
              </CheckoutField>
            </StyledAddressView>
            <StyledAddressView>
              <header>
                <h2>Shipping</h2>
                <StyledCheckboxField>
                  <input
                    tabIndex={-1}
                    type="checkbox"
                    checked={state.sameAsBilling}
                    onChange={e => {
                      dispatch({
                        type: 'sameAsBilling.toggle',
                        value: e.target.checked
                      });
                    }}
                  />
                  <span>Same as billing</span>
                </StyledCheckboxField>
              </header>
              <CheckoutField label="Address" data-field="address1">
                <StyledCheckoutInput
                  tabIndex={state.sameAsBilling ? -1 : 0}
                  autoComplete="shipping street-address"
                  value={state.formValues.shipping_address_1}
                  onChange={e => {
                    dispatch({
                      type: 'shipping_address_1.update',
                      value: e.target.value
                    });
                  }}
                  required
                />
              </CheckoutField>
              <CheckoutField label="Address Line 2" data-field="address2">
                <StyledCheckoutInput
                  tabIndex={state.sameAsBilling ? -1 : 0}
                  autoComplete="shipping street-address"
                  value={state.formValues.shipping_address_2}
                  onChange={e => {
                    dispatch({
                      type: 'shipping_address_2.update',
                      value: e.target.value
                    });
                  }}
                />
              </CheckoutField>
              <CheckoutField label="City" data-field="city">
                <StyledCheckoutInput
                  tabIndex={state.sameAsBilling ? -1 : 0}
                  autoComplete="shipping locality"
                  value={state.formValues.shipping_address_city}
                  onChange={e => {
                    dispatch({
                      type: 'shipping_address_city.update',
                      value: e.target.value
                    });
                  }}
                  required
                />
              </CheckoutField>
              <CheckoutField label="State" data-field="state">
                <StyledCheckoutInput
                  maxLength={2}
                  tabIndex={state.sameAsBilling ? -1 : 0}
                  autoComplete="shipping region"
                  value={state.formValues.shipping_address_state}
                  onChange={e => {
                    dispatch({
                      type: 'shipping_address_state.update',
                      value: e.target.value
                    });
                  }}
                  required
                />
              </CheckoutField>
              <CheckoutField label="Zip" data-field="zip">
                <StyledCheckoutInput
                  tabIndex={state.sameAsBilling ? -1 : 0}
                  autoComplete="shipping postal-code"
                  value={state.formValues.shipping_address_zip}
                  onChange={e => {
                    dispatch({
                      type: 'shipping_address_zip.update',
                      value: e.target.value
                    });
                  }}
                  required
                />
              </CheckoutField>
            </StyledAddressView>
          </StyledCheckoutDetails>
        </Route>
        <Route exact path="/:token/checkout/review">
          <header>
            <StyledBackButton
              type="button"
              data-variant="secondary"
              onClick={() => {
                dispatch({ type: 'edit' });
                cartDispatch({ type: 'checkout' });
              }}
            >
              Edit Information
            </StyledBackButton>
            <h1>Review Payment</h1>
          </header>
          {state.paymentMethod && cartState.surchargeReviewData ? (
            <SurchargeReview
              formValues={state.formValues}
              sameAsBilling={state.sameAsBilling}
              surchargeReviewData={cartState.surchargeReviewData}
              paymentMethod={state.paymentMethod}
              total={getCartTotals(cartState).total}
            />
          ) : null}
        </Route>
      </Switch>
      <StyledCheckoutFooter>
        {cartState.receipt && <ReceiptView receipt={cartState.receipt} />}
        <StyledButton
          data-variant={state.status === 'success' ? 'positive' : 'primary'}
          onClick={
            state.status === 'success'
              ? e => {
                  e.preventDefault();
                  cartDispatch({ type: 'shopping' });
                  cartDispatch({
                    type: 'clear'
                  });
                }
              : undefined
          }
        >
          {isSurchargeEnabled && state.status !== 'review' ? (
            <>
              <div>Review</div>
              <div>Loading...</div>
            </>
          ) : (
            <>
              <div>Pay {formatPrice(getCartTotals(cartState).total)}</div>
              <div>Sending payment...</div>
            </>
          )}
          <div>Payment sent! Continue shopping ➜</div>
        </StyledButton>
        <ReCaptchaTerms id="reCAPTCHA-terms" />
      </StyledCheckoutFooter>
    </StyledCheckoutView>
  );
};
