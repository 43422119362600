import React, { FunctionComponent, useEffect, useState } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import { defaultTheme } from './utils/theme';
import { useCartContext } from './CartContext';
import { Themed, Store } from './types';

type Theme = { colors: Themed['theme']['colors'] };

export const GlobalStyle = createGlobalStyle``;

const createThemeForStore = (store: Store): Theme => {
  const theme = { ...defaultTheme };

  if (store.branding && store.branding.public_url) {
    theme.logoUrl = store.branding.public_url;
  }

  if (store.options && store.options.hosted_hideCompanyName) {
    theme.isCompanyNameHidden = true;
  }

  /** Advanced Customization Options */
  const options =
    store && store.options && store.options.advanced_customization
      ? store.options.advanced_customization
      : null;

  if (!options) return theme;

  const isEnabled = options && options.enabled;
  const isColorPopEnabled = options && options.branding_colors_enabled;
  const colorPop =
    options && options.branding_colors && options.branding_colors.color_pop;

  const isFattmerchantLogoHidden =
    options && options.is_fattmerchant_logo_hidden;

  /**
   * If advanced customization is not enabled, just use the default theme
   * TODO: maybe re-enable this check once we do all of the refactoring of advanced customization
   */
  if (!isEnabled && false) return theme;

  /**
   * If color brand_colors_enabled and the merchant has chosen a color pop
   * use it as the header bg
   */
  if (isColorPopEnabled && colorPop) {
    theme.colors.primaryAccent = colorPop;
    theme.colors.primaryColor = colorPop;
    theme.colors.sidebarBackground = colorPop;
  }

  /**
   * If is_fattmerchant_logo_hidden
   * hide fm logo
   */
  if (isFattmerchantLogoHidden) {
    theme.isFattmerchantLogoHidden = true;
  }

  return theme;
};

export const ThemeWrapper: FunctionComponent = props => {
  const [theme, setTheme] = useState<Theme>({ colors: {} });
  const [cartState] = useCartContext();
  const store = cartState.store;

  useEffect(() => {
    if (store) {
      const theme = createThemeForStore(store);
      if (theme) setTheme(theme);
    }
  }, [store]);

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle theme={theme} />
        {props.children}
      </>
    </ThemeProvider>
  );
};
