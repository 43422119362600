import { useEffect } from 'react';

export const useKeyDown = (
  key: string,
  effect: (event: KeyboardEvent) => void
) => {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === key) {
        effect(event);
      }
    };

    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [effect, key]);
};
