import { ToastAction, ToastActionType, ToastProps } from './Toast.types';
import { ReactNode } from 'react';

export const toast: {
  [key in ToastProps['motif']]: (
    content: any,
    title?: ReactNode,
    options?: Partial<ToastProps>
  ) => ToastAction;
} = {
  success: (content, title, options) =>
    addToast({ ...options, motif: 'success', content, title }),
  warning: (content, title, options) =>
    addToast({ ...options, motif: 'warning', content, title }),
  error: (content, title, options) =>
    addToast({ ...options, motif: 'error', content, title }),
  info: (content, title, options) =>
    addToast({ ...options, motif: 'info', content, title })
};

export function addToast(payload: Partial<ToastProps>): ToastAction {
  return { type: ToastActionType.ADD_TOAST, payload: payload as ToastProps };
}

export function removeToast(payload: string): ToastAction {
  return { type: ToastActionType.REMOVE_TOAST, payload };
}
