import React from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router";
import { StyledButton } from "./Button";
import { parse } from "querystring";

const Container = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;

  > * {
    margin-bottom: 1rem;
  }

  h1 {
    margin: 0;
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
    text-align: center;
  }
`;

export const ErrorPage: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  let { status = "404", message } = parse(search);

  message = message
    ? message
    : status === "404"
    ? "Page not found"
    : "Looks like there was an error. Sorry about that.";

  return (
    <Container>
      <h1>{status}</h1>
      <span>{message}</span>
      <StyledButton
        onClick={() => history.goBack()}
        id="error-go-back-button"
        data-testid="error-go-back-button"
      >
        Go back
      </StyledButton>
    </Container>
  );
};
