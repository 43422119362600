import * as React from 'react';

import styled, { keyframes } from 'styled-components';
import { Receipt } from './types';
import { formatPrice } from './utils/format';

const StyledReceiptLayer = styled.div`
  padding-top: 2rem;

  overflow: hidden;
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const slideUp = keyframes`
from {
  transform: translateY(100%);
}
to {
  transform: none;
}
`;

const StyledReceipt = styled.div`
  box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.1);
  animation: ${slideUp} 0.6s ease-out;
  padding: 1rem;
  width: 60%;
  background: white;
`;

export const ReceiptView: React.FC<{ receipt: Receipt }> = ({ receipt }) => {
  const lineItems = receipt.meta?.lineItems || [];

  return (
    <StyledReceiptLayer>
      <StyledReceipt>
        <figure></figure>
        <h2>Thank you for your order!</h2>
        <div>
          <strong>Order:</strong>
          <ul>
            {lineItems.map(lineItem => {
              return (
                <li key={lineItem.id}>
                  <div>{lineItem.item}</div>
                  <div>
                    x {lineItem.quantity} @ {formatPrice(lineItem.price)}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </StyledReceipt>
    </StyledReceiptLayer>
  );
};
