import styled from 'styled-components';

export const BreadBag = styled.div`
  position: fixed;
  box-sizing: border-box;
  max-height: calc(100vh - 2rem);
  max-width: 100%;
  top: 0;
  right: 0;
  margin: 1rem 1rem 0px 1rem;
  z-index: 999;

  > * {
    margin-bottom: 1rem;
  }
`;
