import { EffectReducer } from 'use-effect-reducer';
import produce from 'immer';
import { formatExpiration, formatPhone } from './utils/format';
import { PaymentMethod } from '@fattmerchantorg/types-omni';

export type CheckoutFormValues = {
  expiry: string;
  month: string;
  year: string;

  firstname: string;
  lastname: string;
  phone: string;
  email: string;

  address_1: string;
  address_2: string;
  address_city: string;
  address_state: string;
  address_zip: string;

  shipping_address_1: string;
  shipping_address_2: string;
  shipping_address_city: string;
  shipping_address_state: string;
  shipping_address_zip: string;
  //stuff for digital wallet processing
  is_digital_wallet?: boolean;
  payment_token?: string;
  token_type?: string;
};

export type CheckoutFormErrors = {
  [K in keyof CheckoutFormValues | 'card_number' | 'card_cvv']?: any;
};

export interface CheckoutState {
  status:
    | 'idle'
    | 'paying'
    | 'tokenizing'
    | 'success'
    | 'failure'
    | 'review'
    | 'digitalwallet';
  sameAsBilling: boolean;
  paymentMethod: PaymentMethod | null;
  formValues: CheckoutFormValues;
  formErrors: CheckoutFormErrors;
}

export const checkoutReducerInitialState: CheckoutState = {
  status: 'idle',
  sameAsBilling: true,
  paymentMethod: null,
  formErrors: {},
  formValues: {
    expiry: '',
    month: '',
    year: '',

    firstname: '',
    lastname: '',
    phone: '',
    email: '',

    address_1: '',
    address_2: '',
    address_city: '',
    address_state: '',
    address_zip: '',

    shipping_address_1: '',
    shipping_address_2: '',
    shipping_address_city: '',
    shipping_address_state: '',
    shipping_address_zip: '',
  },
};

export const checkoutReducer: EffectReducer<CheckoutState, any> = (
  state,
  event,
  exec
) => {
  return produce(state, draft => {
    switch (state.status) {
      case 'idle':
        switch (event.type) {
          case 'expiry.update':
            const expiry = formatExpiration(event.value) || '';
            const [month, year] = String(expiry).split(/\/|-/);
            Object.assign(draft.formValues, {
              expiry: expiry,
              month,
              year: year && `20${year}`,
            });
            break;
          case 'name.update':
            const [firstname, lastname] = event.value.split(/\s+/);

            Object.assign(draft.formValues, {
              firstname,
              lastname,
            });
            break;
          case 'address_1.update':
            draft.formValues.address_1 = event.value;
            if (draft.sameAsBilling)
              draft.formValues.shipping_address_1 = event.value;
            break;
          case 'address_2.update':
            draft.formValues.address_2 = event.value;
            if (draft.sameAsBilling)
              draft.formValues.shipping_address_2 = event.value;
            break;
          case 'address_city.update':
            draft.formValues.address_city = event.value;
            if (draft.sameAsBilling)
              draft.formValues.shipping_address_city = event.value;
            break;
          case 'address_state.update':
            {
              // only keep the first two inputted chars
              // since the api only accepts two char state codes
              const value = (event.value || '').slice(0, 2).toUpperCase();
              draft.formValues.address_state = value;
              if (draft.sameAsBilling)
                draft.formValues.shipping_address_state = value;
            }
            break;
          case 'address_zip.update':
            draft.formValues.address_zip = event.value;
            if (draft.sameAsBilling)
              draft.formValues.shipping_address_zip = event.value;
            break;

          case 'shipping_address_1.update':
            draft.formValues.shipping_address_1 = event.value;
            draft.sameAsBilling = false;
            break;
          case 'shipping_address_2.update':
            draft.formValues.shipping_address_2 = event.value;
            draft.sameAsBilling = false;
            break;
          case 'shipping_address_city.update':
            draft.formValues.shipping_address_city = event.value;
            draft.sameAsBilling = false;
            break;
          case 'shipping_address_state.update':
            draft.formValues.shipping_address_state = (event.value || '')
              .slice(0, 2)
              .toUpperCase();
            draft.sameAsBilling = false;
            break;
          case 'shipping_address_zip.update':
            draft.formValues.shipping_address_zip = event.value;
            draft.sameAsBilling = false;
            break;

          case 'sameAsBilling.toggle':
            if (event.value === true) {
              Object.assign(draft.formValues, {
                shipping_address_1: draft.formValues.address_1,
                shipping_address_2: draft.formValues.address_2,
                shipping_address_city: draft.formValues.address_city,
                shipping_address_state: draft.formValues.address_state,
                shipping_address_zip: draft.formValues.address_zip,
              });
            } else {
              Object.assign(draft.formValues, {
                shipping_address_1: '',
                shipping_address_2: '',
                shipping_address_city: '',
                shipping_address_state: '',
                shipping_address_zip: '',
              });
            }
            draft.sameAsBilling = event.value;
            break;
          case 'phone.update':
            const phone = formatPhone(event.value) || '';
            draft.formValues.phone = phone;
            break;
          case 'email.update':
            draft.formValues.email = event.value;
            break;
          case 'formErrors.merge':
            Object.assign(draft.formErrors, event.value);
            break;
          case 'submit':
            exec({ type: 'tokenize', values: state.formValues });
            draft.status = 'tokenizing';
            break;
          case 'digitalwallet':
            exec({ type: 'pay', values: event.values });
            draft.status = 'paying';
            break;
          default:
            break;
        }
        if (
          !['submit', 'formErrors.update', 'digitalwallet'].includes(event.type)
        ) {
          exec({ type: 'validate', values: state.formValues });
        }

        break;
      case 'review':
        switch (event.type) {
          case 'edit':
            draft.status = 'idle';
            draft.paymentMethod = null;
            break;
          case 'submit':
            exec({ type: 'pay', values: state.formValues });
            draft.status = 'paying';
            break;
          default:
            break;
        }
        break;

      case 'tokenizing':
        switch (event.type) {
          case 'review':
            exec({ type: 'review', values: state.formValues });
            draft.status = 'review';
            draft.paymentMethod = event.value;
            break;
          case 'pay':
            exec({ type: 'pay', values: state.formValues });
            draft.status = 'paying';
            draft.paymentMethod = event.value.paymentMethod;
            break;
          case 'tokenize.failure':
            draft.status = 'idle';
            break;
          default:
            break;
        }
        break;
      case 'paying':
        switch (event.type) {
          case 'payment.success':
            draft.status = 'success';
            exec({ type: 'sendReceipt', receipt: event.receipt });
            break;
          case 'payment.failure':
            draft.status = 'idle';
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  });
};
